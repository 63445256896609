<script setup>
const props = defineProps({
  variant: { type: String, default: "body" },
  as: { type: String, default: "p" },
  color: { type: String, default: "default" },
});

// heading_1
// heading_2
// heading_3
// heading_3

// widget_title
// widget_subtitle

// body
// body_sm
// body_lg

// body_light
// body_light_sm
// body_light_lg

// body_strong
// body_strong_sm
// body_strong_lg

const sizeClasses = computed(() => {
  switch (props.variant) {
    case "heading_1":
      return "text-[30px] leading-tight";
      break;
    case "body":
    case "body_light":
    case "body_strong":
      return "text leading-[22px]";
    case "body_sm":
    case "body_light_sm":
    case "body_strong_sm":
      return "text-[12px] leading-[20px]";
    case "body_lg":
    case "body_light_lg":
    case "body_strong_lg":
      return "text-[16px]  leading-[24px]";
    default:
      return "text";
  }
});

const weightClasses = computed(() => {
  switch (props.variant) {
    case "heading_1":
      return "font-black";
      break;

    default:
      return "font-normal";
  }
});

const colorClasses = computed(() => {
  switch (props.color) {
    case "inverted":
      return "text-mono-white";
  }

  switch (props.variant) {
    case "heading_1":
      return "text-mono-black";
  }

  return "text-mono-dark-grey";
});
</script>

<template>
  <component :is="as" :class="[sizeClasses, weightClasses, colorClasses]"
    ><slot
  /></component>
</template>
