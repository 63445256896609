<script setup>
const props = defineProps({
  color: { type: String, default: "primary" },
  weight: { type: String, default: "bold" },
  size: { type: String },
  to: { type: String },
  external: { type: Boolean, default: false },
});

const sizeClasses = computed(() => {
  switch (props.size) {
    case "sm":
      return "text-[12px]";
    case "md":
      return "text-[14px]";
    case "lg":
      return "text-[16px]";
  }
});

const weightClasses = computed(() => {
  switch (props.weight) {
    case "bold":
      return "font-bold";

    default:
      return "font";
  }
});

const colorClasses = computed(() => {
  switch (props.color) {
    case "inverted":
      return "text-mono-white hover:underline";
    case "primary":
      return "text-primary hover:text-primary-dark";
  }
});

const commonClasses =
  "transition-shadow cursor-pointer rounded ring-0 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring";
</script>

<template>
  <a
    v-if="external"
    :href="to"
    :class="[commonClasses, weightClasses, colorClasses, sizeClasses]"
    target="_blank"
    rel="nofollower noreferrer"
    ><slot
  /></a>
  <NuxtLink
    :to="to"
    :class="[commonClasses, weightClasses, colorClasses, sizeClasses]"
    v-else
    ><slot />
  </NuxtLink>
</template>
